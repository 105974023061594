import { useContext, useEffect, useRef, useState } from 'react';
import { app } from '@microsoft/teams-js';
import { useData } from '@microsoft/teamsfx-react';
import { handleIframeMessage } from '../libs/utils';
import { TeamsFxContext } from '../libs/Context';
import { Preloader } from './Preloader';

export function Welcome() {
	const iframeRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const [iframeLoaded, setIframeLoaded] = useState(false);
	const [firstOpen, setFirstOpen] = useState(true);
	const [userInfo, setUserInfo] = useState(null);
	setTimeout(() => setLoading(false), 2000);

	const { teamsUserCredential } = useContext(TeamsFxContext);
	const { data: teamsUserData } = useData(async () => {
		if (teamsUserCredential) {
			const userInfo = await teamsUserCredential.getUserInfo();
			return userInfo;
		}
	});

	useEffect(() => {
		if (!userInfo) {
			app.initialize().then(async () => {
				const context = await app.getContext();
				setUserInfo({
					appLocale: context?.app?.locale,
					appTheme: context?.app?.theme,
					appHostName: context?.app?.host?.name,
					appLaunchId: context?.app?.appLaunchId,
					userId: context?.user?.id,
					userName: teamsUserData?.displayName,
					userLoginHint: context?.user?.loginHint,
					userPrincipalName: context?.user?.userPrincipalName,
					userTenantId: context?.user?.tenant?.id
				});
			});
		}
	}, [userInfo, teamsUserData?.displayName]);

	useEffect(() => {
		if (iframeRef.current && userInfo && firstOpen && iframeLoaded) {
			setTimeout(() => {
				iframeRef.current.contentWindow.postMessage(
					{ type: 'accountDetailResponse', info: { ...userInfo } },
					'*'
				);
			}, 1250);
			setFirstOpen(false);
		}
	}, [userInfo, firstOpen, iframeLoaded]);

	useEffect(() => {
		window.addEventListener('message', e => handleIframeMessage(e, teamsUserCredential));
		return () => window.removeEventListener('message', handleIframeMessage);
	}, [teamsUserCredential]);

	return (
		<>
			<div style={{ display: userInfo && !loading ? 'none' : 'block' }}>
				<Preloader />
			</div>

			<div style={{ display: userInfo && !loading ? 'block' : 'none' }}>
				<iframe
					ref={iframeRef}
					title="Wellbees App"
					id="wellbeesIframeId"
					// src="http://localhost:3001"
					src="https://app.wellbees.co"
					style={{ border: 0, width: '100%', height: '100%' }}
					onLoad={() => setIframeLoaded(true)}
				/>
			</div>
		</>
	);
}
