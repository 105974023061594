import { BearerTokenAuthProvider, createApiClient } from '@microsoft/teamsfx';
import config from './config';

const getApiClient = teamsUserCredential => {
	return createApiClient(
		`${config.apiEndpoint}/api/`,
		new BearerTokenAuthProvider(async () => (await teamsUserCredential.getToken('')).token)
	);
};

export const getAzureFunction = async (teamsUserCredential, functionName, data) => {
	if (!teamsUserCredential) {
		throw new Error('TeamsFx SDK is not initialized.');
	}
	try {
		const apiClient = getApiClient(teamsUserCredential);
		const response = await apiClient.get(functionName, { params: data });
		return response.data;
	} catch (err) {
		throw new Error(catchError(err));
	}
};

export const postAzureFunction = async (teamsUserCredential, functionName, data) => {
	if (!teamsUserCredential) {
		throw new Error('TeamsFx SDK is not initialized.');
	}
	try {
		const apiClient = getApiClient(teamsUserCredential);
		const response = await apiClient.post(functionName, data);
		return response.data;
	} catch (err) {
		throw new Error(catchError(err));
	}
};

export const deleteAzureFunction = async (teamsUserCredential, functionName, data) => {
	if (!teamsUserCredential) {
		throw new Error('TeamsFx SDK is not initialized.');
	}
	try {
		const apiClient = getApiClient(teamsUserCredential);
		const response = await apiClient.delete(functionName, { params: data });
		return response.data;
	} catch (err) {
		throw new Error(catchError(err));
	}
};

const catchError = err => {
	let funcErrorMsg = '';
	if (err?.response?.status === 404) {
		funcErrorMsg = `There may be a problem with the deployment of Azure Function App, please deploy Azure Function (Run command palette "Teams: Deploy") first before running this App`;
	} else if (err.message === 'Network Error') {
		funcErrorMsg =
			'Cannot call Azure Function due to network error, please check your network connection status and ';
		if (err.config.url.indexOf('localhost') >= 0) {
			funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
		} else {
			funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision" and "Teams: Deploy") first before running this App`;
		}
	} else {
		funcErrorMsg = err.message;
		if (err.response?.data?.error) {
			funcErrorMsg += ': ' + err.response.data.error;
		}
	}
	return funcErrorMsg;
};
