import { useTeamsUserCredential } from '@microsoft/teamsfx-react';
import { TeamsFxContext } from '../libs/Context';
import config from '../libs/config';
import { Welcome } from './Welcome';
import { Preloader } from './Preloader';
import NotAvailable from './NotAvailable';

export default function App() {
	const { loading, theme, themeString, teamsUserCredential, inTeams } = useTeamsUserCredential({
		initiateLoginEndpoint: config.initiateLoginEndpoint,
		clientId: config.clientId
	});

	return (
		<TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
			{loading ? <Preloader /> : !loading && !inTeams ? <NotAvailable /> : <Welcome />}
		</TeamsFxContext.Provider>
	);
}
