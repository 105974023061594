import { postAzureFunction, deleteAzureFunction } from './azureFunctions';
import { app as microsoftTeams } from '@microsoft/teams-js';

const userScopes = ['User.Read', 'Calendars.ReadWrite'];

export const handleIframeMessage = (event, teamsUserCredential) => {
	switch (event?.data?.type) {
		case 'microsoftTeamsLoginRequest':
			getAuthCredentialLogin(event, teamsUserCredential);
			break;
		case 'microsoftTeamsLogoutRequest':
			getAuthCredentialLogout(event, teamsUserCredential);
			break;
		case 'createAppointmentRequest':
			createAppointment(event, teamsUserCredential);
			break;
		case 'openAppointmentRequest':
			openAppointment(event, teamsUserCredential);
			break;
		case 'removeAppointmentRequest':
			removeAppointment(event, teamsUserCredential);
			break;
		default:
			break;
	}
};

// Start: Authentication operations
export const getAuthCredentialLogin = async (event, teamsUserCredential) => {
	if (!teamsUserCredential) {
		throw new Error('TeamsFx SDK is not initialized.');
	}
	try {
		await teamsUserCredential.login(userScopes);
		const { token } = await teamsUserCredential.getToken('');
		event.source.postMessage({ type: 'microsoftTeamsLoginResponse', info: token }, event.origin);
		return token;
	} catch (e) {
		console.log(e);
		throw new Error('Access token is invalid.');
	}
};
export const getAuthCredentialLogout = async (event, teamsUserCredential) => {
	window.localStorage.clear();
	window.location.reload();
};
// End: Authentication operations

// Start: Appointments CRUD operations
export const createAppointment = async (event, teamsUserCredential) => {
	try {
		// event?.data?.info = {expertName, expertType, startTime, endTime}
		const createdEvent = await postAzureFunction(teamsUserCredential, 'createAppointment', event?.data?.info);
		event.source.postMessage({ type: 'createAppointmentResponse', info: { ...createdEvent } }, event.origin);
	} catch (e) {
		console.log(e);
	}
};

export const removeAppointment = async (event, teamsUserCredential) => {
	try {
		// event?.data?.info = {eventId}
		await deleteAzureFunction(teamsUserCredential, 'removeAppointment', event?.data?.info);
		// event.source.postMessage({ type: 'removeAppointmentResponse' }, event.origin);
	} catch (e) {
		console.log(e);
	}
};
// End: Appointments CRUD operations

export const openAppointment = async event => {
	const { url } = event.data.info;
	microsoftTeams.initialize();
	microsoftTeams.openLink(url);
};
